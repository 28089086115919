<template>
  <section class="pt-5">
    <a name="skill" class="shifted-anchor"></a>
    <div class="container skill">
      <div class="header">
        <h3 class="pr-5 pb-3">Skill & Tools</h3>
      </div>
      <div class="content" style="flex-grow: 1">
        <div>
          <h5>Programming Languages</h5>
          <div class="skill-icons">
            <div class="skill-icon">
              <img src="../assets/images/skill/language/java.png" alt="java" />
              <span>Java</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/language/js.png" alt="js" />
              <span>Javascript</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/language/c.png" alt="c" />
              <span>C</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/language/c++.png" alt="c++" />
              <span>C++</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/language/cuda.png" alt="cuda" />
              <span>Cuda</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/language/python.png"
                alt="python"
              />
              <span>Python</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/language/php.png" alt="php" />
              <span>php</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/language/r.png" alt="r" />
              <span>R</span>
            </div>
          </div>
        </div>
        <div>
          <h5>Front-end development</h5>
          <div class="skill-icons">
            <div class="skill-icon">
              <img src="../assets/images/skill/front-end/web.png" alt="web" />
              <span>Web</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/front-end/react.png"
                alt="react"
              />
              <span>React.js</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/front-end/vue.png" alt="vue" />
              <span>Vue.js</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/front-end/redux.png"
                alt="redux"
              />
              <span>Redux</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/front-end/graphql.png"
                alt="graphql"
              />
              <span>GraphQL</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/front-end/relay.png"
                alt="graphql"
              />
              <span>Relay</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/front-end/jquery.png"
                alt="jquery"
              />
              <span>jQuery</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/front-end/material-ui.png"
                alt="material-ui"
              />
              <span>Material-UI</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/front-end/bootstrap.png"
                alt="bootstrap"
              />
              <span>Bootstrap</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/front-end/photoshop.png"
                alt="photoshop"
              />
              <span>photoshop</span>
            </div>
          </div>
        </div>

        <div>
          <h5>Back-end development</h5>
          <div class="skill-icons">
            <div class="skill-icon">
              <img
                src="../assets/images/skill/back-end/node.png"
                alt="nodejs"
              />
              <span>Node.js</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/back-end/express.png"
                alt="express"
              />
              <span>Express.js</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/back-end/django.png"
                alt="django"
              />
              <span>Django</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/back-end/mongodb.png"
                alt="mongodb"
              />
              <span>MongoDB</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/back-end/mysql.png"
                alt="mysql"
              />
              <span>MySQL</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/back-end/aws.png" alt="aws" />
              <span>Amazon AWS</span>
            </div>

            <div class="skill-icon">
              <img src="../assets/images/skill/back-end/gcp.png" alt="GCP" />
              <span>Google Cloud</span>
            </div>

            <div class="skill-icon">
              <img
                src="../assets/images/skill/back-end/heroku.png"
                alt="heroku"
              />
              <span>Heroku</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {};
</script>

<style>
@media screen and (min-width: 1200px) {
  .skill {
    display: flex;
  }
}

.skill-icons {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 3em;
}

.skill-icon {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2em;
}

.skill-icon img {
  height: 90px;
}

@media screen and (max-width: 576px) {
  .skill-icon img {
    height: 60px;
  }

  .skill-icon {
    margin: 1em;
  }
}

.skill-icons span {
  margin-top: 0.8em;
}
</style>
