<template>
  <div id="app">
    <Header />
    <NameTag />
    <hr class="container" />
    <About />
    <hr class="container" />
    <Skill />
    <Footer />
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap.min.js";
import "bootstrap/dist/css/bootstrap.css";
import Header from "./components/Header";
import NameTag from "./components/NameTag";
import About from "./components/About";
import Skill from "./components/Skill";
import Footer from "./components/Footer";

export default {
  name: "App",
  components: {
    Header,
    NameTag,
    About,
    Skill,
    Footer,
  },
};
</script>

<style>
#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50; */
  padding-top: 4rem;
}

a.shifted-anchor {
  position: relative;
  top: -5rem;
  display: block;
}
</style>
