<template>
  <section class="my-5">
    <a name="about" class="shifted-anchor"></a>
    <div class="container my-5" style="text-align: center">
      <h3>About Me</h3>
    </div>
    <div class="container">
      <Timeline :timelineItems="timelineItems" />
    </div>
    <div class="container">
      <p>
        More about my professional experience? Visit my
        <a href="http://www.linkedin.com/in/fssongwei"
          ><i class="fab fa-linkedin"></i>&nbsp;Linkedin</a
        >
        and
        <a :href="`${publicPath}docs/Resume_Wei_Song.pdf`"
          ><i class="far fa-file-alt"></i>&nbsp;Curriculum Vitae</a
        >
      </p>
    </div>
  </section>
</template>

<script>
import { Timeline } from "@fssongwei/wei-ui";

const timelineItems = [
  {
    duration: "2023",
    title: "Bloomberg",
    description: `I will join <a href="https://www.bloomberg.com/company/">Bloomberg</a> at the New York office as a software engineer in January, 2023`,
    img: require("../../src/assets/images/bloomberg.jpg"),
  },
  {
    duration: "2022",
    title: "Facebook",
    description: `From May to August 2022, I worked as a Software Engineer Intern at <a href="https://about.facebook.com">Meta</a> at Menlo Park, California</br>I built the Facebook Reels scoped search feature on Web`,
    img: require("../../src/assets/images/meta.jpg"),
  },
  {
    duration: "2021 - Current",
    title: "Cornell University",
    description: `I am currently a graduate student at
        <a href="https://cis.cornell.edu">Cornell CIS</a>, major in
        <a href="https://infosci.cornell.edu">Information Science</a>
        with a focus on data science`,
    img: require("../../src/assets/images/cornell.jpg"),
  },
  {
    duration: "2021",
    title: "Tencent",
    description: `I worked as a front-end engineer intern at <a href="https://www.tencent.com">Tecent</a> and participated in the development of <a href="https://code.tencent.com/">Tencent Git</a>`,
    img: require("../../src/assets/images/tencent.jpg"),
  },
  {
    duration: "2017 - 2019",
    title: "Northern Arizona University",
    description: `In Aug 2017, I went to <a href="https://www.nau.edu">NAU</a> as
                an exchange student, major in
                <a
                  href="https://nau.edu/school-of-informatics-computing-and-cyber-systems/"
                  >Computer Science</a
                > <br />
                I graduated and received B.S degree with
                <em>Magna Cum Laude</em>`,
    img: require("../../src/assets/images/nau.jpg"),
  },
  {
    duration: "2015 - 2017",
    title: "Jinan University",
    description: `I studied in <a href="https://english.jnu.edu.cn">JNU</a> for
                two years, and received my Bachelor of Engineering degree in
                <a href="https://xxxy.jnu.edu.cn/main.htm">Computer Science</a>`,
    img: require("../../src/assets/images/jnu.jpg"),
  },
  {
    duration: "1997",
    title: "Hello World!",
    description: `It was looong time ago`,
    img: require("../../src/assets/images/born.jpg"),
  },
];

export default {
  name: "About",
  components: {
    Timeline,
  },
  data() {
    return {
      publicPath: process.env.BASE_URL,
      timelineItems,
    };
  },
};
</script>

<style scoped>
a {
  color: #3e7cb1;
}
</style>
