<template>
  <nav class="navbar navbar-expand-xl navbar-dark navbar-footer">
    <div>
      <component
        :is="componentType"
        src="//rf.revolvermaps.com/0/0/3.js?i=0bk1srg908t&amp;b=0&amp;s=40&amp;m=1&amp;cl=998b6b&amp;co=034961&amp;cd=aa0000&amp;v0=60&amp;v1=60&amp;r=1"
        async
      ></component>
      <component
        :is="componentType"
        src="//busuanzi.ibruce.info/busuanzi/2.3/busuanzi.pure.mini.js"
        async
      ></component>
      <span id="busuanzi_container_site_uv"
        ><span id="busuanzi_value_site_uv"></span
      ></span>
    </div>
    <div>&copy;&nbsp;{{ year }} Wei Song</div>

    <div v-if="showICPnumber">
      <a href="https://beian.miit.gov.cn/">粤ICP备2020135812号</a>
    </div>
    <div v-else>
      last update: 2022/11
    </div>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      componentType: "script",
    };
  },
  computed: {
    year() {
      return new Date().getFullYear();
    },
    showICPnumber() {
      return window.location.hostname.includes("david916.com");
    },
  },
};
</script>

<style>
/* .footer {
  width: 33.3%;
  margin: 0 auto;
  font-size: 0.8em;
} */

.navbar-footer {
  padding: 0 1rem;
  height: 50px;
  width: 100%;
  display: flex;
  justify-content: space-between !important;
  color: white;
  font-size: 0.8em;
}

.navbar-footer a {
  color: white;
  font-size: 0.8em;
}
</style>
