<template>
  <nav class="navbar navbar-expand-md navbar-dark fixed-top">
    <div class="container">
      <a class="navbar-brand" href="#"> Wei Song</a>

      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#navItems"
        aria-controls="navbarSupportedContent"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <div class="collapse navbar-collapse" id="navItems">
        <ul class="navbar-nav ml-auto">
          <li class="nav-item">
            <a class="nav-link" href="#about">About</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#skill">Skills</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://blog.wei.ai/categories/Projects/"
              >Projects</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://blog.wei.ai">Blog</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  name: "Header",
};
</script>

<style>
.navbar {
  background: rgba(60, 120, 180, 0.95);
  font-size: 1em;
}

.navbar a {
  font-weight: 300;
}

.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 1) !important;
}
</style>
