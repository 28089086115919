<template>
  <div class="container nameTag">
    <div>
      <h1>Wei Song</h1>
      <p>Software Engineer | Computer & Information Science</p>
      <h3>
        <a href="http://www.github.com/fssongwei">
          <i class="fab fa-github"></i>
        </a>
        <a href="http://www.linkedin.com/in/fssongwei">
          <i class="fab fa-linkedin"></i>
        </a>
        <a href="https://www.facebook.com/fssongwei123">
          <i class="fab fa-facebook"></i>
        </a>
        <a href="https://twitter.com/fssongwei">
          <i class="fab fa-twitter-square"></i>
        </a>
        <a href="https://www.instagram.com/davidsong916/">
          <i class="fab fa-instagram"></i>
        </a>
      </h3>
      <a href="mailto:ws446@cornell.edu">ws446@cornell.edu</a> <br />
      <!-- <a href="docs/Wei_Song_CV.pdf">Curriculum Vitae</a> -->
    </div>
    <div>
      <img src="../assets/images/wei.png" />
    </div>
  </div>
</template>

<script>
import "@fortawesome/fontawesome-free/css/all.min.css";
export default {};
</script>

<style>
.nameTag {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 100px;
  margin-bottom: 90px;
}

.nameTag a {
  color: #3e7cb1;
}

.nameTag img {
  max-width: 250px;
  width: 100%;
  border-radius: 50%;
}

.nameTag i {
  padding-right: 0.4rem;
}
</style>
